<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Personal Prices</h3>

          <div>
            <add-personal-price @hide-dialog="formClosed"/>

            <span class="p-input-icon-left mt-2">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
            </span>
          </div>
        </div>

        <update-personal-price
          :selectedId="openFormParams.selectedItemId"
          :isSelectedEditForm="openFormParams.isSelectedEditForm"
          @hide-dialog="formClosed"
        />

        <DataTable
          :value="personalPrices" :lazy="true" :paginator="true" :rows="pagination?.per_page ?? 25" ref="dt" dataKey="id"
          :first="pagination?.from-1" :totalRecords="pagination.total" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]" :sortField="sort" :sortOrder="order"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>

          <!-- Company name -->
          <Column field="company_name" header="Company name" :sortable="true" />

          <!-- Tool name -->
          <Column field="tool_name" header="Tool name" :sortable="true" />

          <!-- Api price -->
          <Column field="api_price" header="Api price $" :sortable="true" />

          <!-- Profit price -->
          <Column field="sell_price" header="New profit price $" :sortable="true" />

          <!-- Sell price -->
          <Column field="full_price" header="New sell price $" :sortable="true" />

          <Column headerStyle="min-width:15rem;">
            <template #body="slotProps">
              <!-- Edit -->
              <Button
                icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                v-tooltip="'Edit'"
                @click="editItem(slotProps.data)"
              />

              <!-- Delete -->
              <Button
                icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                v-tooltip="'Delete'"
                @click="openConfirmation(slotProps.data)"
              />
              <Dialog header="Confirmation" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
                <div class="flex align-items-center justify-content-center">
                  <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                  <span>Are you sure you want to delete the item?</span>
                </div>
                <template #footer>
                  <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text"/>
                  <Button label="Yes" icon="pi pi-check" @click="deleteItem" class="p-button-text" autofocus />
                </template>
              </Dialog>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toastParamBuilder from "@/builders/ToastParamBuilder";
import AddPersonalPrice from "@/pages/app/PersonalPrices/AddPersonalPrice";
import UpdatePersonalPrice from "@/pages/app/PersonalPrices/UpdatePersonalPrice";
import dataTable from "@/mixins/dataTable";
import store from "@store";

export default {
  mixins: [dataTable],
  data() {
    return {
      lazyParams: {},
      loading: false,
      openFormParams: {
        selectedItemId: 0,
        isSelectedEditForm: false,
      },
      displayConfirmation: false,
      itemIdForDelete: 0,
    }
  },

  components: {
    AddPersonalPrice,
    UpdatePersonalPrice,
  },

  methods: {
    openNew() {
      this.item = {};
      this.submitted = false;
      this.productDialog = true;
    },

    editItem(item) {
      this.openFormParams.isSelectedEditForm = true;
      this.openFormParams.selectedItemId = item.id;
    },

    deleteItem() {
      this.$store.dispatch('PersonalPrices/deletePersonalPrice', {id: this.itemIdForDelete})
        .then(response => {
          this.$toast.add(toastParamBuilder.success(response));
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        })
        .finally(() => {
          this.closeConfirmation();
        });
    },

    formClosed() {
      this.openFormParams.isSelectedEditForm = false;
    },

    openConfirmation(item) {
      this.displayConfirmation = true;
      this.itemIdForDelete = item.id;
    },

    closeConfirmation() {
      this.displayConfirmation = false;
    },
  },

  computed: {
    ...mapGetters(
      'PersonalPrices', [
        'personalPrices',
        'pagination',
      ]
    ),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('PersonalPrices/getPersonalPrices', to.query);
      next((vm) => {
        vm.loading = false;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('PersonalPrices/getPersonalPrices', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
