<template>
  <Toast/>

  <Dialog v-model:visible="itemDialog" :style="{width: '850px'}" header="Update Personal Price" :modal="true" class="p-fluid">
    <!-- Company -->
    <div class="field">
      <label for="company">Company:</label>
      <p id="company">{{ companyName }}</p>
    </div>

    <!-- Tools -->
    <div class="field">
      <label for="tool">Tool:</label>
      <p id="tool">{{ toolName }}</p>
    </div>

    <div class="field">
      <label for="api_price">API Price $:</label>
      <p id="api_price">{{ apiPrice }}</p>
    </div>

    <!-- New profit price -->
    <div class="field pt-2">
      <label for="name">New Profit Price*</label>
      <InputText
        id="name" v-model.trim="v$.item.sell_price.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.sell_price.$invalid && submitted}"
      />
      <span v-if="v$.item.sell_price.$error && submitted">
        <span id="email-error" v-for="(error, index) of v$.item.sell_price.$errors" :key="index">
          <small class="p-error">{{ error.$message.replace('Value', 'New Profit Price') }}</small>
        </span>
      </span>
      <small
        v-else-if="(v$.item.sell_price.$invalid && submitted) || v$.item.sell_price.$pending.$response"
        class="p-error">{{ v$.item.sell_price.required.$message.replace('Value', 'New Profit Price') }}
      </small>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem(!v$.$invalid)" />
    </template>
  </Dialog>
</template>

<script>
import { required, decimal } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import PersonalPriceResource from '@http/PersonalPriceResource';
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  data() {
    return {
      itemDialog: false,
      formHeader: '',
      item: {
        sell_price: '',
      },
      toolName: '',
      companyName: '',
      apiPrice: 0,
      submitted: false,
    }
  },

  props: {
    isSelectedEditForm: Boolean,
    selectedId: Number,
  },

  itemResource: null,

  created() {
    this.itemResource = new PersonalPriceResource();
  },

  methods: {
    hideDialog() {
      this.itemDialog = false;
      this.submitted = false;
    },

    saveItem(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.$store.dispatch('PersonalPrices/updatePersonalPrice', { id: this.item.id, payload: this.item })
        .then(response => {
          this.$toast.add(toastParamBuilder.success(response));
          this.closeDialog();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    loadEdit(id) {
      this.itemResource.getPersonalPriceById(id)
        .then(data => {
          this.item = data;
          this.toolName = data.tool_name;
          this.companyName = data.company_name;
          this.apiPrice = data.api_price;

          this.submitted = false;
          this.itemDialog = true;
        });
    },

    closeDialog() {
      this.itemDialog = false;
      this.item = {};
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },
  },

  watch: {
    isSelectedEditForm(value) {
      if (value) {
        this.loadEdit(this.selectedId);
      }
    },

    itemDialog(value) {
      if (! value) {
        this.$emit('hideDialog');
      }
    },
  },

  validations() {
    return {
      item: {
        sell_price: { required, decimal },
      },
    }
  },
}
</script>
