<template>
  <Toast/>

  <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>

  <Dialog v-model:visible="itemDialog" :style="{width: '850px'}" header="Create Personal Price" :modal="true" class="p-fluid">
    <!-- Companies -->
    <div class="field pb-3">
      <div class="p-float-label">
        <Dropdown id="company" :options="companies" optionLabel="name" v-model="selectedCompany" />
        <label for="company">Company*</label>
      </div>

      <small
        v-if="(v$.selectedCompany.$invalid && submitted)"
        class="p-error">{{ v$.item.company_id.required.$message.replace('Value', 'Company') }}
      </small>
    </div>

    <!-- Tools -->
    <div class="field">
      <div class="p-float-label">
        <Dropdown id="tool" :options="tools" optionLabel="name" v-model="selectedTool" />
        <label for="tool">Tool*</label>
      </div>

      <small
        v-if="(v$.selectedTool.$invalid && submitted)"
        class="p-error">{{ v$.selectedTool.required.$message.replace('Value', 'Tool') }}
      </small>
    </div>

    <div v-if="selectedTool">
      <div>API Price $: {{ selectedTool.price ? selectedTool.price : 0 }}</div>
      <div>Current Profit Price $: {{ selectedTool.profit ? selectedTool.profit : 0 }}</div>
    </div>

    <!-- New profit price -->
    <div class="field pt-5" v-if="selectedCompany && selectedTool">
      <label for="name">New Profit Price*</label>
      <InputText
        id="name" v-model.trim="v$.item.sell_price.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.sell_price.$invalid && submitted}"
      />
      <span v-if="v$.item.sell_price.$error && submitted">
        <span id="email-error" v-for="(error, index) of v$.item.sell_price.$errors" :key="index">
          <small class="p-error">{{ error.$message.replace('Value', 'New Profit Price') }}</small>
        </span>
      </span>
      <small
        v-else-if="(v$.item.sell_price.$invalid && submitted) || v$.item.sell_price.$pending.$response"
        class="p-error">{{ v$.item.sell_price.required.$message.replace('Value', 'New Profit Price') }}
      </small>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem(!v$.$invalid)" />
    </template>
  </Dialog>
</template>

<script>
import { required, decimal } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import CompanyResource from '@http/CompanyResource';
import ToolResource from '@http/ToolResource';
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  data() {
    return {
      itemDialog: false,
      item: {
        company_id: 0,
        tool_id: 0,
        sell_price: '',
      },
      companies: [],
      selectedCompany: null,
      tools: [],
      selectedTool: null,
      submitted: false,
    }
  },

  methods: {
    openNew() {
      this.selectedCompany = this.selectedTool = null;
      this.item = {};
      this.loadDataForSelects();
      this.submitted = false;
      this.itemDialog = true;
    },

    hideDialog() {
      this.itemDialog = false;
      this.submitted = false;
    },

    saveItem(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.item.company_id = this.selectedCompany.id;
      this.item.tool_id = this.selectedTool.id;

      this.$store.dispatch('PersonalPrices/createPersonalPrice', this.item)
        .then(response => {
          this.$toast.add(toastParamBuilder.success(response));
          this.closeDialog();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    closeDialog() {
      this.itemDialog = false;
      this.item = {};
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },

    loadDataForSelects() {
      CompanyResource.allCompanies()
        .then(({data}) => {
          this.companies = data;
        });

      ToolResource.all()
        .then(({data}) => {
          this.tools = data;
        });
    },
  },

  watch: {
    itemDialog(value) {
      if (! value) {
        this.$emit('hideDialog');
      }
    },
  },

  validations() {
    return {
      item: {
        sell_price: { required, decimal },
      },
      selectedCompany: { required },
      selectedTool: { required },
    }
  },
}
</script>
